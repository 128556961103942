// Основные стили карты
html, body {
  height: 100%;
  min-height: 100%;
}
.main_container {
  height: 100%;
  min-height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
.main_row{
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
.map {
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}
.menu{
  height: 100%;
  min-height: 100%;
  width: 100%;
}
.overflow-bg{
  height: 100%;
  min-height: 100%;
  background-color: white;
}

.triangle{
  color: red;
}
.navbarmain{
  padding-left: 0;
  padding-right: 0;
}


//Стили окна на карте
.customControl {
  background-color: #fff;
  padding: 10px;
  border-radius: 3px;
  max-width: 270px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
}
.objCount{
  padding: 0 0 10px 0;
}
.control-panel{
  //position: absolute;
  //right: 330px;
  //background: white;
  // top: 50px;
  width: 250px;
//  height: 300px;
  // box-shadow: 0 1px 2px 1px rgba(0,0,0,.15), 0 2px 5px -3px rgba(0,0,0,.15);
  //border-radius: 3px;
  //padding-bottom: 50px;
}
.display-container{
  padding: 0 15px 15px 0;
  font-size: 13px;
}
.button-owner{
  top: 10px;
  bottom: 10px;
  //text-align: center;
 // position: absolute;
//  width: 100%;
}
//стили правого меню
.header-info {
  font-weight: bold;
  margin-top: 15px;
  font-size: 120%;
}

.light-text {
  color: lightgray;
  margin: 0 0 0 15px;
}

//****************************************************
//Стили карточки тревоги
//****************************************************
.alarms{
  height: 100%;
  overflow-y: scroll;
}
.alarm-info{
  width: 100%;
}
.alarm-cart {
  background: red;
  color: white;
  padding: 10px 15px 10px 15px;
  margin-bottom: 2px;
  cursor: pointer;
  position: relative;
  width: 100%;
}
.alarm-title{
  padding-bottom: 0px;
  line-height: 0.9;
}
.alarm-body{
  padding-bottom: 8px;
  line-height: 1;
}

.alarm-close {
  position: absolute;
  right: -5px;
  top: -2px;
  color: white;
  border: 1px solid white;
  border-radius: 50%;
  padding: 0 7px 1px 7px;
  background: red;
}

.car-reaction {
  padding: 3px 0 3px 0;
  border: 2px solid #eaeaea;
  margin-bottom: 2px;
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
.displayed{
  border: 2px solid #34b800;
}

.nearest-auto {
  padding: 5px 0 5px 10px;
}

.car-time {
  font-weight: bold;
  font-size: 20px;
}

.car-dist {
  font-size: 20px;
}
.car-model{
  font-size: 16px;
}
.car-gbr{
  font-size: 16px;
}

.info-panel {
  max-height: 100%;
  overflow: auto;
  position: relative;
}

//картинки

.type-owner {
  //padding-top: 8px;
}

.type-owner span {
  width: 25px;
  height: 25px;
  display: inline-block;
  // border: 1px solid transparent;
}

.type-selected {
  border: 1px solid white !important;
  border-radius: 50%;
}

.type-owner > span:hover {
  opacity: 0.7;
}

.type-btn-owner > span:hover {
  opacity: 0.7;
}

.type-all {
  padding: 3px 5px;
  display: inline-block;
  top: -7px;
  position: relative;
}


.type-drive {
  //background: url('../../public/img/driv.svg');
  background: url('../../gbrmap/static/img/driv.svg');
}

.type-ob {
  background: url('../../gbrmap/static/img/ob.svg');
}

.type-walk {
  background: url('../../gbrmap/static/img/walk.svg');
}


//картинки конец

//.type-refresh{
//  background: url('../../gbrmap/static/img/sync-alt-light.svg');
//}
//.type-sms-send-all{
//  background: url('../../public/img/envelope-light-white.svg');
//  margin-right: 15px;
//}

.send-sms-car {
  width: 25px;
  height: 25px;
  background: url('../../gbrmap/static/img/envelope-light-white.svg');
  position: absolute;
  bottom: 0;
  right: 5px;
}

.send-sms {
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: 0;
  right: 5px;
}

.send-sms-api {
  width: 25px;
  height: 25px;
  background: url("../../gbrmap/static/img/envelope-light.svg");
  position: absolute;
  bottom: 0;
  right: 5px;
}

.send-sms:hover {
  opacity: 0.7;
}

.send-sms-api:hover {
  opacity: 0.7;
}

.send-sms-ok {
  background: url("../../gbrmap/static/img/smsok.svg");
}

.arrow-icon {
  position: absolute;
  width: 64px;
  height: 48px;
  //background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='23' height='25'><polygon fill='green' fill-opacity='0.5' stroke='black' stroke-width='2' points='11,17 1,24 11,1 22,24'/></svg>");
  background: url("../../gbrmap/static/img/car.svg");
  transform-origin: 50% 50%;
}

.arrow-opacity-icon {
  position: absolute;
  width: 64px;
  height: 48px;
  background: url("../../gbrmap/static/img/car-lost.svg");
  transform-origin: 50% 50%;
}

.arrow-icon:hover {
  cursor: pointer;
}

.arrow-red-icon {
  position: absolute;
  width: 64px;
  height: 48px;
  background: url("../../gbrmap/static/img/car-red.svg");
  transform-origin: 50% 50%;
}

.square_layout {
  position: absolute;
  left: -50%;
  top: -50%;
  width: 64px;
  height: 48px;
  border: 1px solid #218703;
  color: #218703;
  background: url("../../gbrmap/static/img/car-red.svg");
}

.all-sms{
  background: white;
  position: absolute;
  padding: 10px;
  border: 1px solid #e2e2e2;
  border-radius: 3px;
  z-index: 999;
  right: 50px;
  top: 27%;
  -webkit-box-shadow: 0px 2px 6px -1px rgba(50, 50, 50, 0.75);
  -moz-box-shadow:    0px 2px 6px -1px rgba(50, 50, 50, 0.75);
  box-shadow:         0px 2px 6px -1px rgba(50, 50, 50, 0.75);
}

.gbr-info, .gbr-check{
  padding-right: 5px;
}
.gbr-info-row-bold{
  font-weight: bold;
}
.overflow-bg{
  position: absolute;
  z-index: 1;
  background: white;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
}

input[name=gbr-phone-radio]{
  zoom: 1.5;
}

.gbr-phone-radio{
  zoom: 1.5;
}

.close-sms{
  position: absolute;
  right: 5px;
  top: 0;
}
.close-container{
  height:15px;
}

//Стили иконок машин

.car-icon {
  position: absolute;
  width: 66px;
  height: 41px;
}

.car-icon:hover {
  cursor: pointer;
}


.connection-error{
  color: red;
  font-size: 130%;
  border: thick dashed red;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  line-height: 0.9;
}
.navbar-brand{
  margin-right: 5px;
}
.trip_content{
  background-color: white;
  //height: 300px;
  //width: 300px;
  //border-radius: 5px;
}
.text-small{
  font-size: 70%;
}
.card-title{
  margin-bottom: 0;
}
.data_log_content{
  background-color: white;
  //height: 300px;
  //width: 300px;
  //border-radius: 5px;
}
.data_log_content{

  border: 1px solid rgb(0, 123, 255); /* Параметры границы */
  border-radius: 5px;
  //border-color : rgb(0, 123, 255);
  //border-width: thin;
  margin-bottom: 16px;
}

.log-icon {
  position: absolute;
  width: 18px;
  height: 24px;
}

.log-icon:hover {
  cursor: pointer;
}
.refresh_button{
  //font-size: 130%;
  height: 30px;
  width: 100%;
  text-align:right;
}
.type-sms-send-all{
  padding-right: 10px;
  margin-right: 10px;
}
.confirm_button{
//  height: 30px;
//  width: 100%;
  padding-bottom: 5px;
}

#myCanvas{
  position: absolute;
}
.imgDiv{
  position: relative;
}
.name-text{
  padding-bottom: 10px;
}